<script setup lang="ts">
import { useSupportStore } from "@/store/support";

const supportStore = useSupportStore();
</script>

<template>
  <div>
    <DropdownMenu button-class="w-full focus:outline-none flex-shrink-0 p-0 m-0" open="up">
      <template #dropdown-button="{ open }">
        <div
          class="w-full group flex rounded-md p-2 text-sm font-medium leading-6 group text-dark-gray dark:text-white hover:bg-white-gray dark:hover:bg-dark-gray"
        >
          <div class="group-hover:translate-x-1 transition-transform flex items-center gap-x-3">
            <Icon name="material-symbols:help" class="h-6 w-6 shrink-0 text-medium-gray" aria-hidden="true" />
            Help Center
          </div>
        </div>
      </template>
      <template #dropdown-items>
        <DropdownItem @click="supportStore.toggleContactSupportModal({ active: true })">Contact support</DropdownItem>
        <DropdownItem @click="supportStore.toggleContactSalesModal({ active: true })">Contact sales</DropdownItem>
      </template>
    </DropdownMenu>
  </div>
</template>
